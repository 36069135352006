<template>

<div class="help">

	<div class="help-head">

		<com-inner class="help-head-inner">

			<h2>How you can help</h2>

		</com-inner>

	</div>

	<div class="help-content" :data-slot="slot" v-touch:swipe="onSwipe">

		<com-inner class="help-content-inner">

			<div class="help-content-item" v-for="id in content" :key="id">

				<div class="help-content-item-image" v-bgimage="block.box[id].image" />
				<div class="help-content-item-name">{{ block.box[id].title }}</div>
				<div class="help-content-item-text">{{ block.box[id].text }}</div>
				<router-link :to="$link(block.box[id].url)" class="help-content-item-button">Learn more</router-link>

			</div>

		</com-inner>

	</div>

	<div class="help-pips" v-if="window.is.mobile">

		<div class="help-pips-wrapper"><span :class="{'is-active': slot === 1}"></span><span :class="{'is-active': slot === 2}"></span><span :class="{'is-active': slot === 3}"></span></div>

		<div class="help-pips-text">Swipe for more information</div>

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	data: function() {

		return {
			content: ['a', 'b', 'c'],
			slot: 1
		}

	},

	methods: {

		onSwipe: function(e) {

			this.slot = (e === 'left') ? this.slot + 1 : this.slot - 1
			if (this.slot < 1) this.slot = 1
			if (this.slot > 3) this.slot = 3

		}

	}

}

</script>

<style scoped>

.is-embed .help {
	margin-left: -20px;
	margin-top: -20px;
	margin-bottom: -20px;
	width: calc(100% + 40px);
}

.is-mobile .help {
	margin-bottom: 50px;
}

.is-desktop .help:before {
	content: '';
	position: absolute;
	width: 364px;
	height: 890px;
	left: calc(50% - 660px);
	pointer-events: none;
	z-index: 2;
	top: -100px;
	background-image: url(~@/assets/home.help.png);
}

.help-head {
	padding: 60px 20px 100px 20px;	
	background-position: 50% 50%;
	background-size: cover;
}

.is-mobile .help-head {
	padding: 40px 10px 100px 10px;
}

.is-aa .help-head {
	background-image: url(~@/assets/banner.aa.jpg);
}

.is-afa .help-head {
	background-image: url(~@/assets/banner.afa.jpg);
}

.is-stars .help-head {
	background-image: url(~@/assets/banner.stars.jpg);
}

.is-desktop .help-head-inner {
	padding-left: 250px!important;
}

.help-head h2 {
	font-size: 44px;
	color: #fff;
	text-transform: uppercase;
}

.is-tablet .help-head h2 {
	font-size: 32px;
}

.is-mobile .help-head h2 {
	font-size: 28px;
	text-align: center;
}

.help-content {
	margin-top: -40px;
	background-color: #fff;
	border-radius: 0px 17px 0px 0px;
	padding: 100px 20px 60px 20px;
	width: calc(100% - 20px);
}

.is-mobile .help-content {
	margin-top: -30px;
	border-radius: 0px;
	margin-left: 10px;
	padding: 0px;
	background-color: transparent;
}

.help-content-inner {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}

.is-desktop .help-content-inner {
	padding-left: 250px!important;
}

.is-mobile .help-content-inner {
	display: block;
}

.help-content-item {
	background-color: #f5f5f5;
	border-radius: 21px;
	padding: 20px;
}

.is-mobile .help-content-item {
	transition: all 100ms linear;
	width: calc(100vw - 40px);
	position: absolute;
}

.is-mobile .help-content[data-slot="1"] .help-content-item:nth-child(1),
.is-mobile .help-content[data-slot="2"] .help-content-item:nth-child(2),
.is-mobile .help-content[data-slot="3"] .help-content-item:nth-child(3) {
	left: 0%;
	position: relative;
	opacity: 1;
}

.is-mobile .help-content[data-slot="2"] .help-content-item:nth-child(1),
.is-mobile .help-content[data-slot="3"] .help-content-item:nth-child(1),
.is-mobile .help-content[data-slot="3"] .help-content-item:nth-child(2) {
	left: -100%;
	opacity: 0;
	top: 0px;
}

.is-mobile .help-content[data-slot="1"] .help-content-item:nth-child(2),
.is-mobile .help-content[data-slot="1"] .help-content-item:nth-child(3),
.is-mobile .help-content[data-slot="2"] .help-content-item:nth-child(3) {
	left: 100%;
	opacity: 0;
	top: 0px;
}

.help-content-item-image {
	width: 140px;
	height: 140px;
	margin-top: -70px;
	border: 4px solid #5E9F48;
	border-radius: 50%;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin: -90px auto 0px auto;
	background-color: #fff;
}

.is-mobile .help-content-item-image {
	width: 90px;
	height: 90px;
	margin: -60px auto 0px auto;
}

.help-content-item-name {
	color: #1a234c;
	font-size: 28px;
	text-transform: uppercase;
	padding: 20px 0px;
	text-align: center;
}

.is-mobile .help-content-item-name {
	font-size: 24px;
}

.help-content-item-text {
	font-size: 16px;
	color: #343434;
	line-height: 30px;
}

.is-mobile .help-content-item-text {
	font-size: 14px;
	line-height: 20px;
}

.help-content-item-button {
	background-color: #1a234c;
	cursor: pointer;
	display: block;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	border-radius: 22px;
	font-weight: 300;
	white-space: nowrap;
	height: 44px;
	line-height: 44px;
	width: 160px;
	padding: 0px 20px;
	font-size: 12px;
	letter-spacing: 1.2px;
	margin: 20px auto;
}

.help-pips {
	padding: 20px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.help-pips-wrapper {
	display: flex;
	justify-content: center;
}

.help-pips-wrapper span {
	display: block;
	border: 2px solid #1D79D1;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin: 0px 4px;
	transition: all 100ms linear;
}

.help-pips-wrapper span.is-active {
	background-color: #1D79D1;
}

.help-pips-text {
	text-transform: uppercase;
	font-size: 10px;
	color: #1A234C;
	margin-top: 20px;
}

</style>
